// @noflow

/*
Public API:

    Mixcloud.FooterWidget(key, { disablePushstate: false, disableHotkeys: false, disableUnloadWarning: false, light: true, ... }).then(function(widget) {
        // widget is a widget API that is ready
    });

    Mixcloud.navigate(url);

*/

import { isPlaywrightTest, isProduction } from 'shared/utils/consts';

import Deferred from './deferred';
// Passing in window.Mixcloud so this still works even if noConflict is used
((Mixcloud, window, document) => {
    // Passing in window and document so they can be minified

    //      If the initial page has a form then this won't work - may want to set target="top" for all forms and ignore them
    //      On the initial page the form could be targeted at an invisible iframe that becomes the currentIframe?
    //      Could monkey patch setting window.location and window.location.href or other ways to cause a page to navigate without clicking a link
    //      Is it possible to detect page change not caused by calling top.navigate? If so, just pushState instead of replaceState when the page loads

    const WIDGET_BASE_URL = isProduction
        ? 'https://www.mixcloud.com'
        : 'http://localhost:8092';
    const SCRIPT_SRC = isProduction
        ? '//widget.mixcloud.com/media/js/footerWidgetApi.js'
        : '//localhost:8004/media/js/footerWidgetApi.js';
    const pushstateSupport = (() => {
        if (window.navigator.appVersion.includes('MSIE 10')) {
            // IE10 has pushstate bugs that aren't worth fixing in this
            return false;
        }

        return !!(
            window.history &&
            window.history.pushState &&
            window.history.replaceState
        );
    })();
    const HASH_RE = /#(.*)/;
    let hasPlayed = false;

    // ------------------------------------------------------ Document ready

    const documentReady = (() => {
        const promise = new Deferred();

        if (document.readyState === 'complete') {
            promise.resolve();
        } else {
            addEvent(document, 'DOMContentLoaded', onReady);
            addEvent(window, 'load', onReady);
        }

        function onReady() {
            removeEvent(document, 'DOMContentLoaded', onReady);
            removeEvent(window, 'load', onReady);
            promise.resolve();
        }

        return promise;
    })();

    // ------------------------------------------------------ Event helpers

    function addEvent(element, eventName, handler) {
        if (element.addEventListener) {
            element.addEventListener(eventName, handler, false);
        } else {
            element.attachEvent(`on${eventName}`, handler);
        }
    }

    function removeEvent(element, eventName, handler) {
        if (element.removeEventListener) {
            element.removeEventListener(eventName, handler, false);
        } else {
            element.detachEvent(`on${eventName}`, handler);
        }
    }

    // ------------------------------------------------------ Click interceptor

    function onClick(event) {
        if (
            event.defaultPrevented ||
            event.which > 1 ||
            event.metaKey ||
            event.ctrlKey ||
            event.shiftKey ||
            event.altKey
        ) {
            return;
        }

        let link = event.target;

        while (link && link.parentNode && link.nodeName !== 'A') {
            link = link.parentNode;
        }

        const url = link.href;

        if (
            link.nodeName !== 'A' ||
            url.length === 0 ||
            link.target.length !== 0
        ) {
            return;
        }

        const hashMatch = url && url.match(HASH_RE);

        if (hashMatch) {
            const urlWithoutHash = url.replace(hashMatch[0], '');

            if (
                urlWithoutHash === '' ||
                urlWithoutHash === document.location.href
            ) {
                // Same page hash link
                return;
            }
        }

        // Open different origin pages in a new tab/window or playing pages in browsers that don't support pushstate
        if (!pushstateSupport) {
            if (hasPlayed) {
                link.setAttribute('target', '_blank');
            }

            return;
        }

        if (getOrigin() !== getOrigin(link)) {
            link.setAttribute('target', '_blank');

            return;
        }

        event.preventDefault();

        Mixcloud.navigate(link.pathname + link.search);
    }

    // ------------------------------------------------------ History

    const historyWrapper = (() => {
        let currentState = null;
        let currentId = 1;

        function state(url) {
            return { url, id: currentId++ };
        }

        return {
            replace(url) {
                currentState = state(url);
                window.history.replaceState(
                    currentState,
                    null,
                    currentState.url,
                );
            },
            push(url) {
                currentState = state(url);
                window.history.pushState(currentState, null, currentState.url);
            },
            shouldPop({ id }) {
                return currentState.id !== id;
            },
            updateScrollPosition(scrollPosition) {
                if (!currentState) {
                    return;
                }
                currentState.scrollPosition = scrollPosition;
                window.history.replaceState(
                    currentState,
                    null,
                    currentState.url,
                );
            },
        };
    })();

    function onPopState({ state }) {
        if (!state || !state.url) {
            return;
        }

        if (historyWrapper.shouldPop(state)) {
            parentNavigate(state.url, true, state.scrollPosition);
        }
    }

    // ------------------------------------------------------ Frame communication

    function postToParent(messageType) {
        window.top.postMessage(
            {
                MixcloudNavigation: true,
                messageType,
                // eslint-disable-next-line
                params: Array.prototype.slice.call(arguments, 1),
            },
            getOrigin(),
        );
    }

    function onPostMessage({ data }) {
        if (!data || !data.MixcloudNavigation) {
            return;
        }
        frameCallbacks[data.messageType].apply(null, data.params);
    }

    // ------------------------------------------------------ Load

    let scrollPositionOnLoad = null;
    let currentIframe = null;
    let iframeContainer = null;

    function parentNavigate(url, fromPopState, scrollPosition) {
        if (!iframeContainer) {
            // Not initialised yet
            documentReady
                .then(() => {
                    parentNavigate(url, fromPopState, scrollPosition);
                })
                .catch((e) => {
                    console.log(e);
                });

            return;
        }

        scrollPositionOnLoad = null;

        if (!fromPopState) {
            historyWrapper.updateScrollPosition(getIframeScrollPosition());
        }

        if (currentIframe) {
            currentIframe.parentNode.removeChild(currentIframe);
        } else {
            setUpPageStyles();
        }

        scrollPositionOnLoad = scrollPosition;

        iframeContainer.innerHTML =
            '<iframe width="100%" height="100%" frameborder="0"></iframe>';
        currentIframe = iframeContainer.childNodes[0];
        // Setting the location this way instead of src= because Firefox sometimes refuses to
        // load the frame when done that way (particularly when loading from popstate
        getIframeWindow().location = getOrigin() + encodeURI(url);

        if (!fromPopState) {
            historyWrapper.push(url);
        }
    }

    function setUpPageStyles() {
        const style = document.createElement('style');
        style.innerHTML =
            'html,body{padding:0 !important;margin:0 !important;overflow:hidden !important}';
        document.head.appendChild(style);
        iframeContainer.setAttribute(
            'style',
            'position: absolute; top: 0; left: 0; right: 0; bottom: 60px',
        );
    }

    // ------------------------------------------------------ Frame

    function getCurrentUrl() {
        return window.location.pathname + window.location.search;
    }

    function getOrigin(link = window.location) {
        if (link.origin) {
            return link.origin;
        }
        const origin = `${link.protocol}//${link.hostname}`;

        if (
            !link.port ||
            (link.protocol === 'http:' && link.port === '80') ||
            (link.protocol === 'https:' && link.port === '443')
        ) {
            return origin;
        }

        return `${origin}:${link.port}`;
    }

    function getIframeWindow() {
        return (
            currentIframe &&
            (
                currentIframe.contentDocument ||
                currentIframe.contentWindow.document
            ).defaultView
        );
    }

    function getIframeScrollPosition() {
        const iframeWindow = getIframeWindow() || window; // Return the main page if there is no iframe yet
        const iframeDocument = iframeWindow.document;

        return (
            iframeWindow.pageYOffset ||
            (iframeDocument.compatMode === 'CSS1Compat' &&
                iframeDocument.documentElement.scrollTop) ||
            iframeDocument.body.scrollTop ||
            0
        );
    }

    function detectInFrame() {
        try {
            if (window.top !== window.self) {
                return true;
            }
        } catch (err) {
            return true;
        }

        return false;
    }

    // ------------------------------------------------------ Forms

    function patchForms() {
        // Lazy form support - for now just open in a new tab
        const forms = document.getElementsByTagName('form');

        for (let i = 0; i < forms.length; i++) {
            forms[i].setAttribute('target', '_blank');
        }
    }

    // ------------------------------------------------------ Player

    const HOTKEY_AVOIDING_TAGS_RE = /^(INPUT|TEXTAREA|A)$/i;

    let widgetApi = null;

    function isSpacebarEvent(event) {
        if (event.metaKey || event.ctrlKey) {
            return false;
        }

        const keycode = event.which || event.keyCode;

        if (keycode !== 32) {
            return false;
        } // Only spacebar for now

        if (event.target.tagName.match(HOTKEY_AVOIDING_TAGS_RE)) {
            return false;
        }

        return true;
    }

    function getWidgetIframeSrc(key, options) {
        let src = `${WIDGET_BASE_URL}/widget/iframe/?feed=${encodeURIComponent(
            key,
        )}&footer_widget=1`;

        if (options.stylecolor) {
            src += `&stylecolor=${encodeURIComponent(options.stylecolor)}`;
        }

        if (options.hide_artwork) {
            src += '&hide_artwork=1';
        }

        if (options.autoplay) {
            src += '&autoplay=1';
        }

        if (options.light) {
            src += '&light=1';
        }

        if (options.html5audio) {
            src += '&html5audio=1';
        }

        if (options.disableUnloadWarning) {
            src += '&disable_unload_warning=1';
        }

        return src;
    }

    let isPlaying = false;

    function widgetReady({ disableHotkeys, disableUnloadWarning }) {
        widgetApi.events.play.on(onPlay);
        widgetApi.events.pause.on(onStop);
        widgetApi.events.buffering.on(onStop);
        widgetApi.events.ended.on(onStop);
        widgetApi.events.error.on(onStop);

        if (!disableHotkeys) {
            addEvent(document, 'keydown', (event) => {
                if (widgetApi && isSpacebarEvent(event)) {
                    widgetApi.togglePlay();
                }
            });
            widgetApi.enableHotkeys();
        }

        // Don't enable the unload prompt during tests, as this causes tones of additional work in Playwright
        if (!isPlaywrightTest) {
            if (!disableUnloadWarning) {
                addEvent(window, 'beforeunload', (event) => {
                    if (!isPlaying) {
                        return;
                    }
                    event.returnValue =
                        'Are you sure you want to stop listening and leave this page?';

                    return event.returnValue;
                });
            }
        }
    }

    function onPlay() {
        hasPlayed = true;
        isPlaying = true;
    }

    function onStop() {
        isPlaying = false;
    }

    // ------------------------------------------------------ Simple embed

    function checkForSimpleEmbed() {
        // Checks to see if the script tag has been embedded like this:
        // <script src="...">{"feed": "/.../"}</script>

        // The usual method to do this would be to check for the last script tag in the document but
        // it's possible to embed with async so we need to check all tags
        const scriptTags = document.getElementsByTagName('script');

        let tag = null;
        let src;

        for (let i = 0; i < scriptTags.length; i++) {
            src = scriptTags[i].getAttribute('src');

            if (src && src.replace(/https?:/i, '') === SCRIPT_SRC) {
                tag = scriptTags[i];
                break;
            }
        }

        if (!tag) {
            return;
        }

        try {
            const opts = JSON.parse(tag.innerHTML);
            const feed = opts.feed;

            if (feed) {
                delete opts.feed;
                Mixcloud.FooterWidget(feed, opts);
            }
        } catch (err) {}
    }

    // ------------------------------------------------------ Simple play buttons

    addEvent(document, 'click', (event) => {
        let target = event.target;

        while (
            target &&
            target.parentNode &&
            (!target.hasAttribute ||
                !target.hasAttribute('data-mixcloud-play-button'))
        ) {
            target = target.parentNode;
        }

        if (
            !target ||
            !target.hasAttribute ||
            !target.hasAttribute('data-mixcloud-play-button')
        ) {
            return;
        }

        postToParent('play', target.getAttribute('data-mixcloud-play-button'));
    });

    // ------------------------------------------------------ Parent/Child

    if (detectInFrame()) {
        // ----------------------------------- Child
        addEvent(document, 'click', onClick);

        Mixcloud.navigate = (url) => {
            postToParent('navigate', url);
        };
        postToParent(
            'load',
            getCurrentUrl(),
            document.title,
            window.location.hash,
        );
        Mixcloud.FooterWidget = () => new Deferred(); // Can't create a footer widget in the child, but don't error
        addEvent(document, 'keydown', (event) => {
            if (isSpacebarEvent(event)) {
                postToParent('togglePlay');
            }
        });

        documentReady.then(patchForms).catch((e) => {
            console.log(e);
        });
    } else {
        // ----------------------------------- Parent
        var frameCallbacks = {
            load(url, title, hash) {
                historyWrapper.replace(url); // The iframe might have been redirected
                document.title = title;

                if (hash) {
                    window.location.hash = hash;
                }

                if (scrollPositionOnLoad) {
                    getIframeWindow().scrollTo(
                        0,
                        scrollPositionOnLoad > 20 ? scrollPositionOnLoad : 0,
                    );
                    scrollPositionOnLoad = null;
                }
            },
            play(feed) {
                if (widgetApi) {
                    widgetApi.load(feed, true);
                }
            },
            togglePlay() {
                if (widgetApi) {
                    widgetApi.togglePlay();
                }
            },
        };

        frameCallbacks.navigate = (url) => {
            parentNavigate(url);
        };

        let installed = false;

        Mixcloud.FooterWidget = (key, options) => {
            if (installed) {
                throw Error(
                    'Mixcloud Widget API: You can only create one footer widget',
                );
            }
            installed = true;
            const deferred = new Deferred();

            if (!options.disablePushstate) {
                // This is needed whether pushstate is supported or not (it handles target=_blank whilst playing)
                addEvent(document, 'click', onClick);

                if (pushstateSupport) {
                    Mixcloud.navigate = frameCallbacks.navigate;
                    addEvent(window, 'message', onPostMessage);
                    addEvent(window, 'popstate', onPopState);
                    historyWrapper.replace(getCurrentUrl());
                }
            }

            documentReady
                .then(() => {
                    if (pushstateSupport && !options.disablePushstate) {
                        // Wrap the body
                        iframeContainer = document.createElement('div');
                        iframeContainer.setAttribute(
                            'class',
                            'mixcloud-footer-widget-body-wrapper',
                        );
                        document.body.appendChild(iframeContainer);

                        // Move everything into the container
                        while (document.body.childNodes.length > 1) {
                            iframeContainer.appendChild(
                                document.body.childNodes[0],
                            );
                        }
                    }

                    patchForms();

                    // Container across the bottom of the screen
                    const widgetContainer = document.createElement('div');
                    widgetContainer.setAttribute(
                        'style',
                        'position: fixed; left: 0; bottom: 0; right: 0; height: 60px; z-index: 10',
                    );
                    widgetContainer.setAttribute(
                        'class',
                        'mixcloud-footer-widget-container',
                    );
                    document.body.appendChild(widgetContainer);

                    // Body padding
                    let paddingBottom = 0;
                    const bodyStyle = window.getComputedStyle(document.body);

                    if (window.getComputedStyle) {
                        paddingBottom = parseFloat(
                            bodyStyle['padding-bottom'].replace(/px$/, ''),
                        );
                    }
                    document.body.style.paddingBottom = `${
                        paddingBottom + 60
                    }px`;

                    // Iframe widget embed
                    widgetContainer.innerHTML = `<iframe width="100%" height="100%" frameborder="0" src="${getWidgetIframeSrc(
                        key,
                        options,
                    )}"></iframe>`;

                    // Widget API
                    const widget = Mixcloud.PlayerWidget(
                        widgetContainer.childNodes[0],
                    );
                    widget.ready
                        .then(() => {
                            widgetApi = widget;
                            widgetReady(options);
                            deferred.resolve(widget);
                        })
                        .catch((e) => {
                            console.log(e);
                        });
                })
                .catch((e) => {
                    console.log(e);
                });

            return deferred;
        };

        checkForSimpleEmbed();
    }
})(window.Mixcloud, window, document);
